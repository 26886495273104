@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Faktum';
  src:
    local('Faktum'),
    url(/fonts/Faktum.woff) format('woff');
}

/* inter-100 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-200 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-200.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-600 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-600.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-800 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-800.svg#Inter')
      format('svg'); /* Legacy iOS */
}
/* inter-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/fonts/inter/inter-v12-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg#Inter')
      format('svg'); /* Legacy iOS */
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 0 0% 97%;
    --muted-foreground: 255 7% 35%;

    --popover: 0deg 0% 100%;
    --popover-foreground: 222.2deg 47.4% 11.2%;

    --tooltip: 40 5% 12%;
    --tooltip-foreground: 0 0% 100%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 260 76% 61%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 51 30% 95%;
    --accent-foreground: 255 4% 19%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 260 88% 84%;

    --radius: 0.5rem;

    --font-sans: 'Inter';
    --font-faktum: 'Faktum';

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 260 2% 47%;

    --accent: 51 30% 95%;
    --accent-foreground: 255 4% 19%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --tooltip: 224 71% 4%;
    --tooltip-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 260 76% 61%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 260 88% 84%;

    --radius: 0.5rem;

    --font-sans: 'Inter';
    --font-faktum: 'Faktum';

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  body {
    @apply text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  .container {
    @apply max-sm:px-4;
  }
}

h1 {
  @apply font-faktum;
}

.input {
  @apply rounded-lg border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yonder-500 focus:ring-offset-0;
}

.input-error {
  @apply border-red-500 placeholder-red-500;
}

.input-error-message {
  @apply mt-2 max-w-md break-words text-sm text-red-500;
}

.primary-link {
  @apply text-xs font-normal text-yonder-500 hover:text-yonder-400;
}

.secondary-link {
  @apply text-base font-normal text-gray-500 hover:text-gray-400;
}

.card.image-full {
  --n: transparent;
}

/* background: */
.progress::-webkit-progress-bar {
  @apply bg-yonder-main;
}
.progress {
  @apply bg-yonder-main;
}

/* value: */
.progress::-webkit-progress-value {
  @apply bg-yonder-500;
}
.progress::-moz-progress-bar {
  @apply bg-yonder-500;
}
.progress {
  color: #894cee;
}

/* TODO: Remove when we remove filepond */
.filepond--credits {
  @apply hidden !important;
}

/* .filepond--root {
    height: 10em;
} */

/* .filepond--drop-label {
    height: 10em;
    color: rgb(207, 44, 52);
} */

/* End filepond */

/* Styles for react datepicker tailwind integration */
.react-datepicker__input-container input {
  @apply form-input block w-full rounded-2xl border border-gray-300 bg-white text-base shadow-sm md:text-sm;
}

.react-datepicker-popper {
  @apply z-40 w-96 rounded-2xl bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute left-auto right-0 top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute right-0 top-12 z-10 w-96 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 items-center justify-center rounded-xl py-1 text-sm leading-loose text-gray-700 transition hover:cursor-pointer hover:bg-yonder-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-yonder-500 focus:ring-offset-0;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-yonder-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-yonder-500 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-yonder-500 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-yonder-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-yonder-500 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-yonder-500 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__aria-live {
  @apply sr-only;
}

/* Styles for intercom to make work with radix ui dialog */
.intercom-launcher {
  pointer-events: auto;
}

/* Styles for hellosign modal to make work with radix ui dialog */
.x-hellosign-embedded--in-modal {
  pointer-events: auto;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
